import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';


class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  render() {
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" style={{ marginTop: 100 }}>
          <Container>
            <Row>
              <Col className="text-center midTitle">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.TITLE1 : TXT.ABOUT.TITLE1}
                </h1>
              </Col>
            </Row>
            <span className="separator_100" />
            <Row>
              <Col Col xs="12" md={{ size: 8, offset: 2 }} >
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_MAIN : TXT.ABOUT.CONTENT_MAIN}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 6, offset: 3 }} offset-md-3 className="has-text-second" >
                <ul>
                  <li>{APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_LISTITEM1 : TXT.ABOUT.CONTENT_LISTITEM1}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_LISTITEM2 : TXT.ABOUT.CONTENT_LISTITEM2}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_LISTITEM3 : TXT.ABOUT.CONTENT_LISTITEM3}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_LISTITEM4 : TXT.ABOUT.CONTENT_LISTITEM4}</li>
                  <br />
                  <li>{APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_LISTITEM5 : TXT.ABOUT.CONTENT_LISTITEM5}</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 8, offset: 2 }} >
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_P1 : TXT.ABOUT.CONTENT_P1}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 8, offset: 2 }} >
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_P2 : TXT.ABOUT.CONTENT_P2}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 8, offset: 2 }} >
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_P3 : TXT.ABOUT.CONTENT_P3}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={{ size: 8, offset: 2 }} >
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.CONTENT_P4 : TXT.ABOUT.CONTENT_P4}
                </p>
              </Col>
            </Row>
            <Row className="text-right">
              <Col xs="12" md={{ size: 8, offset: 2 }} >
                <p className="ml-auto">
                  {APP_LANG === 'EN' ? TXTEN.ABOUT.SIGNATURE_TITLE : TXT.ABOUT.SIGNATURE_TITLE}
                  <br />
                  <br />
                  <strong>
                    {APP_LANG === 'EN' ? TXTEN.ABOUT.SIGNATURE_TEXT : TXT.ABOUT.SIGNATURE_TEXT}
                  </strong>
                </p>
              </Col>
            </Row>
            <span className="separator_100" />
            <Row />
          </Container>
        </div>
      </Layout>
    );
  }

}

export default About;
